/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import ibrahimaziz-library

// IMPORT - END


// MEASUREMENT - START

/* SPACE - START */

$SPACE_ZERO: 0px
$SPACE_THIN: 2px
$SPACE_TINY: 5px
$SPACE_NARROW: 7.5px
$SPACE_LITTLE: 10px
$SPACE_SMALL: 15px
$SPACE_MEDIUM: 20px
$SPACE_LARGE: 25px
$SPACE_HUGE: 30px
$SPACE_ENORMOUS: 40px
$SPACE_BIG: 50px

$SPACE_NAVIGATION_MARGIN: 80px
$SPACE_NAVIGATION_SHOW: 180px
$SPACE_NAVIGATION_HIDE: 60px
$SPACE_NAVIGATIONITEM_SHOW: 0px
$SPACE_NAVIGATIONITEM_HIDE: -20px
$SPACE_HEADERGENERAL_TOP: 60px

/* SPACE - END */

/* BORDER - START */

$BORDER_THIN: 1px
$BORDER_MEDIUM: 2px
$BORDER_THICK: 3px

/* BORDER - END */

/* SIZE - START */

$SIZE_ICON_MDPI: 48px
$SIZE_ICON_XXLDPI: calc(($SIZE_ICON_MDPI * 0.75) / 2)
$SIZE_ICON_XLDPI: calc($SIZE_ICON_MDPI / 2)
$SIZE_ICON_LDPI: calc($SIZE_ICON_MDPI * 0.75)
$SIZE_ICON_HDPI: calc($SIZE_ICON_MDPI * 1.5)
$SIZE_ICON_XHDPI: calc($SIZE_ICON_MDPI * 2)
$SIZE_ICON_XXHDPI: calc($SIZE_ICON_MDPI * 3)
$SIZE_ICON_XXXHDPI: calc($SIZE_ICON_MDPI * 4)

/* SIZE - END */

/* INPUT - START */

$PADDING_INPUT_EDGE: 5px
$PADDING_INPUT_CHECKBOX: 4px
$PADDING_INPUT_SIDE: 10px
$HEIGHT_INPUT_GENERAL: 36px
$HEIGHT_INPUT_SINGLELINE: $HEIGHT_INPUT_GENERAL //- ($PADDING_INPUT_EDGE * 2)
$HEIGHT_SELECT_SINGLELINE: $HEIGHT_INPUT_GENERAL //+ ($PADDING_INPUT_EDGE * 2)
$WIDTH_INPUT_SQUARE: $HEIGHT_INPUT_GENERAL //- ($PADDING_INPUT_SIDE * 2)
$WIDTH_INPUT_SINGLELINE: calc($HEIGHT_INPUT_GENERAL * 2)
$WIDTH_INPUT_LICENSEPLATE_PREFIX: 70px
$WIDTH_INPUT_LICENSEPLATE_INFIX: 140px
$WIDTH_INPUT_LICENSEPLATE_SUFFIX: 90x
$WIDTH_INPUT_MOBILEPHONE_PREFIX: 90px
$RADIUS_INPUT_GENERAL: calc($HEIGHT_INPUT_GENERAL / 3)
$WIDTH_BORDER_INPUTTHIN: 2px
$WIDTH_BORDER_INPUTTHICK: calc($WIDTH_BORDER_INPUTTHIN * 2)
$SIZE_INPUT_RADIO: calc($HEIGHT_INPUT_GENERAL - ($WIDTH_BORDER_INPUTTHICK * 2) - ($PADDING_INPUT_EDGE * 2))
$SIZE_INPUT_CHECKBOX: 14px
$RADIUS_INPUT_CHECKBOXINNER: $WIDTH_BORDER_INPUTTHICK
$RADIUS_INPUT_CHECKBOXOUTER: calc($WIDTH_BORDER_INPUTTHICK * 2)
$HEIGHT_TEXTAREA_GENERAL: calc($HEIGHT_INPUT_GENERAL * 2)
$HEIGHT_TEXTAREA_READONLY: 80px
$HEIGHT_TEXTAREA_HEADER: 100px
$WIDTH_INPUT_PHONE: 120px
$HEIGHT_INPUT_CHECkBOX: 17px
$WIDTH_INPUT_CHECkBOX: 17px
$HEIGHT_BUTTON_CHECkBOX: 20px
$WIDTH_BUTTON_CHECkBOX: 20px
$RADIUS_INPUT_CHECkBOX: 5px

/* INPUT - END */

/* LINK - START */

$HEIGHT_LINK_BUTTON: calc($HEIGHT_INPUT_GENERAL + ($HEIGHT_INPUT_GENERAL / 6))
$RADUIS_LINK_BUTTON: calc($HEIGHT_INPUT_GENERAL / 2)
$RADUIS_LINK_BUTTONTABLE: 10px
$SIZE_LINK_BUTTONCOPY: 24px
$RADUIS_LINK_BUTTONCONVERSATION: 12px
$WIDTH_LINK_BUTTON_CONVERSATION: 300px
$SIZE_LINK_BUTTONATTACHMENT: 20px

/* LINK - END */

/* LAYOUT - END */

$WIDTH_LAYOUT_SIGNIN: 400px
$WIDTH_LAYOUT_SIGNIN_COMPACT: 300px
$RADIUS_LAYOUT_FORM: 24px
$HEIGHT_LAYOUT_FOOTER: 130px
$WIDTH_LABEL_HIGHLIGHT: 80px
$RADIUS_LABEL_HIGHLIGHT: 6px
$SIZE_LAYOUT_PROFILEIMAGE: 100px
$WIDTH_BORDER_PROFILETHICK: 6px
$WIDTH_BORDER_PROFILETHIN: 2px
$SIZE_LAYOUT_PROFILECONTAINER: 260px
$SIZE_LAYOUT_PROFILEFRAME: 112px
$WIDTH_LAYOUT_PROFILENAMEINITIAL: 100px
$HEIGHT_LAYOUT_PROFILENAMEINITIAL: 70px
$WIDTH_TABLE_THUMBNAILSFIGURE: 140px
$HEIGHT_TABLE_THUMBNAILSFIGURE: 160px
$SIZE_TABLE_THUMBNAILSICON: 100px
$SIZE_TABLE_THUMBNAILSICONSMALL: 30px
$WIDTH_TABLE_ATTACHMENT: 400px
$WIDTH_TABLE_ATTACHMENTBIG: calc(($WIDTH_TABLE_ATTACHMENT / 2) + ($WIDTH_TABLE_ATTACHMENT / 8))
$WIDTH_TABLE_ATTACHMENTMEDIUM: calc(($WIDTH_TABLE_ATTACHMENT / 5))
$WIDTH_TABLE_ATTACHMENTSMALL: calc(($WIDTH_TABLE_ATTACHMENT - $WIDTH_TABLE_ATTACHMENTBIG - $WIDTH_TABLE_ATTACHMENTMEDIUM) / 2)
$RADIUS_TABLE_GENERAL: 12px
$RADIUS_BUTTON_GENERAL: 12px
$HEIGHT_LOADING_BAR: 8px
$SIZE_TICKET_ICON: 32px
$RADIUS_TICKET_STATUS: 6px
$RADIUS_DASHBOARD_LIST: 3px
$HEIGHT_CHART_LIST: 300px
$WIDTH_LABEL_CONVERSATIONGENERAL: 100px
$WIDTH_LABEL_CONVERSATIONSTATUS: 100px
$WIDTH_LAYOUT_LOGOHEADER: 200px
$HEIGHT_LAYOUT_LOGOHEADER: 50px
$WIDTH_LAYOUT_LOGOABOUT: 192px
$HEIGHT_LAYOUT_LOGOABOUT: 192px
$PADDING_SPAN_CONVERSATIONPROFILE: 12px
$HEIGHT_LAYOUT_BUTTONATTACHMENTCONTAINER: 45px
$HEIGHT_LAYOUT_LISTMEDIUM: 600px
$HEIGHT_LAYOUT_LISTSHORT: 400px
$WIDTH_LABEL_DASHBOARD: 150px
$WIDTH_LABEL_POLICYGENERAL_STATUS: 120px
$RADIUS_BUTTON_GENERAL: 12px
$WIDTH_SCROLLBAR_VERTICAL: 4px
$RADIUS_LEGEND_CHART: 5px
$WIDTH_LEGEND_CHART: 26px
$HEIGHT_LEGEND_CHART: 26px
$WIDTH_SCROLLBAR_VERTICAL: 4px

/* LAYOUT - END */


/* BORDER - START */

$BORDER_THIN: 1px
$BORDER_MEDIUM: 2px
$BORDER_THICK: 3px

/* BORDER - END */


// MEASUREMENT - END


// URL - START

/* RESOURCE - START */

$URL_RESOURCE_GENERAL: "../../"
$URL_THEME_PRIMARY: "sakina/"
$URL_THEME_SECONDARY: "sakina/"
$URL_THEME_TERTIARY: "sakina/"
$URL_THEME_QUATERNARY: "sakina/"

/* RESOURCE - END */

/* INPUT - START */

$URL_INPUT_RESOURCE: $URL_RESOURCE_GENERAL + "inputs/" + $URL_THEME_PRIMARY
// $URL_INPUT_RESOURCE: $URL_RESOURCE_GENERAL + "Input/" + $URL_THEME_SECONDARY
// $URL_INPUT_RESOURCE: $URL_RESOURCE_GENERAL + "Input/" + $URL_THEME_TERTIARY
// $URL_INPUT_RESOURCE: $URL_RESOURCE_GENERAL + "Input/" + $URL_THEME_QUATERNARY

/* INPUT - END */

/* ICON - START */

$URL_ICON_RESOURCE: $URL_RESOURCE_GENERAL + "icons/"+ $URL_THEME_PRIMARY
// $URL_ICON_RESOURCE: $URL_RESOURCE_GENERAL + "Icon/" + $URL_THEME_SECONDARY
// $URL_ICON_RESOURCE: $URL_RESOURCE_GENERAL + "Icon/" + $URL_THEME_TERTIARY
// $URL_ICON_RESOURCE: $URL_RESOURCE_GENERAL + "Icon/" + $URL_THEME_QUATERNARY

/* ICON - END */

/* SHAPE - START */

$URL_SHAPE_RESOURCE: $URL_RESOURCE_GENERAL + "shapes/" + $URL_THEME_PRIMARY
// $URL_SHAPE_RESOURCE: $URL_RESOURCE_GENERAL + "Icon/" + $URL_THEME_SECONDARY
// $URL_SHAPE_RESOURCE: $URL_RESOURCE_GENERAL + "Icon/" + $URL_THEME_TERTIARY
// $URL_SHAPE_RESOURCE: $URL_RESOURCE_GENERAL + "Icon/" + $URL_THEME_QUATERNARY

/* SHAPE - END */

/* LOGO - START */

$URL_LOGO_RESOURCE: $URL_RESOURCE_GENERAL + "logos/" + $URL_THEME_PRIMARY
// $URL_LOGO_RESOURCE: $URL_RESOURCE_GENERAL + "Icon/" + $URL_THEME_SECONDARY
// $URL_LOGO_RESOURCE: $URL_RESOURCE_GENERAL + "Icon/" + $URL_THEME_TERTIARY
// $URL_LOGO_RESOURCE: $URL_RESOURCE_GENERAL + "Icon/" + $URL_THEME_QUATERNARY

/* LOGO - END */

/* PHOTO - START */

$URL_PHOTO_RESOURCE: $URL_RESOURCE_GENERAL + "photos/" + $URL_THEME_PRIMARY
// $URL_PHOTO_RESOURCE: $URL_RESOURCE_GENERAL + "Icon/" + $URL_THEME_SECONDARY
// $URL_PHOTO_RESOURCE: $URL_RESOURCE_GENERAL + "Icon/" + $URL_THEME_TERTIARY
// $URL_PHOTO_RESOURCE: $URL_RESOURCE_GENERAL + "Icon/" + $URL_THEME_QUATERNARY

/* PHOTO - END */

/* TYPEFACE - START */

$URL_FONT_RESOURCE: $URL_RESOURCE_GENERAL + "fonts/"
$URL_FONT_PRIMARY: 'Helvetica Neue/'
$URL_FAMILYPRIMARY_PRIMARY: 'helveticaneuecyr_roman'
$URL_FAMILYPRIMARY_SECONDARY: 'helveticaneuecyr_bold'
$URL_FAMILYPRIMARY_TERTIARY: 'helveticaneuecyr_light'
$URL_FONT_SECONDARY: 'Bebas Neue/'
$URL_FAMILYSECONDARY_PRIMARY: 'bebasneue_regular'

/* TYPEFACE - END */

// URL - END


// FONT - START

/* INPUT - START */

$FONT_INPUT_GENERAL: 1.2em
$FONT_SELECT_SEVERITY: 0.95em
$FONT_INPUT_TEXTAREA_HEADERHEADLINE: 1.1em

/* INPUT - END */

/* LINK - START */

$FONT_LINK_GENERAL: 1.2em

/* LINK - END */

/* LABEL - START */

$FONT_LABEL_H3: 1.5em
$FONT_LABEL_H4: 1.35em
$FONT_LABEL_LEGEND: 1.35em
$FONT_LABEL_GENERAL: 1.175em
$FONT_LABEL_PROPERTY: 1.175em
$FONT_LABEL_H1: 5em
$FONT_LABEL_SPAN: 0.95em
$FONT_LABEL_H2: 1.8em
$FONT_LABEL_SUBTITLE: 1em
$FONT_LABEL_PROFILENAME: 1.35em
$FONT_LABEL_PROFILENAMEINITIAL: 4.2em
$FONT_LABEL_PROFILEPOSITION: 1.2em
$FONT_LABEL_HEADERINFO: 1.2em
$FONT_SIZE_CLOSE: 1.4em
$FONT_LIST_NAVIGATIONITEM: 1.2em // 1.35em
$FONT_LIST_NAVIGATIONSUBITEM: 0.9em // 1.175em
$FONT_DASHBOARD_SPAN: 1.03em
$FONT_DASHBOARD_SPANLIST: 0.9em
$FONT_SPAN_TABLEPAGE: 1.175em
$FONT_SPAN_LOADINGPERCENTAGE: 5em
$FONT_DATA_TICKETLABEL: 1em
$FONT_LIST_TABLETITLE: 1.175em
$FONT_SPAN_TICKETSTATUS: 0.95em
$FONT_SPAN_PROFILENAMEINITIAL: 1.6em
$FONT_DATALIST_HEADERINFO: 1.1em
$FONT_DATALIST_OVERVIEW: 1.1em
$FONT_SIZE_LINK_EMATERAI: 0.9375em
$FONT_SIZE_SPAN_EMATERAI: 1.125em
$FONT_SIZE_SPAN_STATUS_APPROVAL: 0.75em
$FONT_SIZE_SPAN_EBUDGETING: 1.5em

/* LABEL - END */

/* DASHBOARD - START */

$FONT_DASHBOARD_EMATERAI: 1.7em

/* DASHBOARD - END */

// FONT - END